import React from "react";

import "../style.scss";
import "./style.scss";

const CardBrand = ({image, text, link}) => (
    <a className="card card-brand rounded ie" href={link} aria-label={`${text}`} >
        <div style={{backgroundImage: `url(${image})`}}></div>
        <h5 className="subtitle">{text}</h5> 
    </a>
)

export default CardBrand
