import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeaderSimple from "../components/HeaderSimple";
import CardBrand from "../components/Cards/CardBrand";

import Panorama from '../images/marchi/marchi-panorama.jpg';
import Bio from '../images/marchi/marchi-bio.jpg';
import Eco from '../images/marchi/marchi-eco.jpg';
import ITesori from '../images/marchi/marchi-tesori.jpg';
import Semplici from '../images/marchi/marchi-semplici.jpg';
import calici from '../images/marchi/marchi-calici.jpg';
import FrescheBonta from '../images/marchi/marchi-fresche-bonta.jpg';
import zoa from '../images/marchi/marchi-zoa.jpg';
import arkalia from '../images/marchi/marchi-arkalia.jpg';
import arkaliaBio from '../images/marchi/marchi-arkalia-bio.jpg';

import "../utils/general.scss";
import "../utils/page-marchi.scss";

const Marchi = ({ pageContext }) => (
    <Layout pageContext={pageContext}>
        <SEO title={pageContext.localeResources["home.pages.marchi.title"]}>
            {typeof window !== 'undefined' &&
                <link href={window.location} rel="canonical" />
            }
        </SEO>

        <HeaderSimple subtitle={pageContext.localeResources["marchi.subtitle"]} title={pageContext.localeResources["marchi.title"]} />

        <section className="section-marchi colorful-bg">
            <div className="container section">
                <CardBrand
                    link="https://www.pampanorama.it/marchi/pam-panorama.html"
                    image={Panorama}
                    text="Pam Panorama"
                />
                <CardBrand
                    link="https://www.pampanorama.it/marchi/bio.html"
                    image={Bio}
                    text="Bio"
                />
                <CardBrand
                    link="https://www.pampanorama.it/marchi/eco.html"
                    image={Eco}
                    text="Eco"
                />
                <CardBrand
                    link="https://www.pampanorama.it/marchi/i-tesori.html"
                    image={ITesori}
                    text="I Tesori"
                />
                <CardBrand
                    link="https://www.pampanorama.it/marchi/semplici-e-buoni.html"
                    image={Semplici}
                    text="Semplici e Buoni"
                />
                <CardBrand
                    link="https://www.pampanorama.it/marchi/calici-divini.html"
                    image={calici}
                    text="Calici Divini"
                />
                <CardBrand
                    link="https://www.pampanorama.it/marchi/fresche-bonta.html"
                    image={FrescheBonta}
                    text="Fresche Bontà"
                />
                <CardBrand
                    link="https://www.pampanorama.it/marchi/zoa.html"
                    image={zoa}
                    text="Zoa Premium"
                />
                {/* <CardBrand
                    link="https://www.pampanorama.it/marchi/pam-panorama.html"
                    image={integra}
                    text="Integra"
                /> */}
                <CardBrand
                    link="https://www.pampanorama.it/marchi/arkalia.html"
                    image={arkalia}
                    text="Arkalia"
                />
                <CardBrand
                    link="https://www.pampanorama.it/marchi/arkalia-bio.html"
                    image={arkaliaBio}
                    text="Arkalia Bio"
                />
            </div>
        </section>
    </Layout>
)

export default Marchi
